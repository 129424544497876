jQuery(document).ready(function ($) {

  // FADE AND SLIDE ANIMATION
  var items = $('.slide-in');

  // Check visibility on page load
  items.each(function () {
    if (isElementInView($(this)) && !$(this).hasClass('slideIn')) {
      $(this).addClass('slideIn');
    }
  });

  //DEBOUNCE
  // Listen for scroll event
  let scrollTimeout

  function handleScroll() {
    clearTimeout(scrollTimeout)

    scrollTimeout = setTimeout(function () {
      items.each(function () {
        if (isElementInView($(this)) && !$(this).hasClass('slideIn')) {
          $(this).addClass('slideIn');
        }
      })
    }, 200)
  }

  $(window).on('scroll', handleScroll);


  // Check element visibility
  function isElementInView(element) {
    var windowHeight = $(window).height();
    var elementTop = element.offset().top;
    var elementBottom = elementTop + element.outerHeight();

    return (elementTop <= (windowHeight + $(window).scrollTop()) && elementBottom >= $(window).scrollTop());
  }

  function fadeElementIn(element) {
    $(element).addClass('fade-in');
  }


  // LOADING BAR ANIMATION
  $(window).on('load', function () {
    var loadingBar = $('.loading-bar');
    var width = 0;
    var interval = setInterval(animateLoadingBar, 10);

    function animateLoadingBar() {
      if (width >= 100) {
        clearInterval(interval);
      } else {
        width++;
        loadingBar.css('width', width + '%');
      }
    }
  });
});